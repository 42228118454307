import { IconButton, SvgIcon } from '@material-ui/core';
import React, { useState } from 'react';

import IceBreakerModal from './IceBreakerModal';

export interface IIceBreakerIconButtonProps {
    Username: string;
    Profilid: string;
    onSend?(): void;
}

export const IceBreakerIconButton = (props: IIceBreakerIconButtonProps) => {
    const { Username, Profilid, onSend } = props;
    const [isOpen, setisOpen] = useState<boolean>(false);

    return (
        <>
            <IconButton onClick={() => setisOpen(true)}>
                <SvgIcon>
                    <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512">
                        <g>
                            <g>
                                <path
                                    d="M479.906,4.364c-2.792-4.035-8.076-5.488-12.541-3.444L134.783,153.255c-4.327,1.982-6.69,6.711-5.675,11.362
			c1.014,4.65,5.131,7.966,9.892,7.966h103.882L48.749,310.614c-3.59,2.552-5.117,7.133-3.777,11.329
			c1.34,4.196,5.239,7.044,9.644,7.044h95.419L32.148,496.039c-2.777,3.935-2.396,9.282,0.909,12.785
			c1.97,2.088,4.654,3.176,7.366,3.176c1.839,0,3.693-0.501,5.346-1.53L407.86,285.065c4.746-2.954,6.199-9.197,3.244-13.944
			c-1.718-2.76-4.549-4.397-7.549-4.711c-0.075-0.009-0.157-0.013-0.235-0.02c-0.218-0.018-0.436-0.031-0.655-0.035
			c-0.05-0.001-0.091-0.008-0.142-0.008l-99.696-0.139l157.194-129.043c3.306-2.714,4.549-7.213,3.107-11.239
			c-1.442-4.025-5.255-6.71-9.53-6.71c-0.002,0-0.004,0-0.006,0l-77.761,0.046L478.707,17.315
			C482.193,13.86,482.699,8.402,479.906,4.364z M159.445,415.858l-81.919,50.995l81.919-116.084V415.858z M264.466,276.266
			c0,0.004,0,0.009,0,0.013v74.202l-84.774,52.773v-84.388c0-0.15-0.016-0.297-0.022-0.445c-0.005-0.125-0.009-0.247-0.019-0.372
			c-0.068-0.848-0.24-1.664-0.504-2.441c-0.029-0.087-0.063-0.173-0.095-0.26c-0.129-0.346-0.274-0.684-0.438-1.012
			c-0.021-0.043-0.034-0.087-0.056-0.129c-0.009-0.017-0.021-0.031-0.029-0.049c-0.196-0.373-0.415-0.731-0.655-1.074
			c-0.054-0.078-0.113-0.15-0.169-0.226c-0.195-0.264-0.403-0.518-0.623-0.762c-0.075-0.083-0.148-0.167-0.225-0.247
			c-0.28-0.291-0.573-0.569-0.886-0.824c-0.042-0.033-0.085-0.062-0.127-0.095c-0.284-0.225-0.581-0.432-0.889-0.627
			c-0.087-0.055-0.175-0.108-0.264-0.16c-0.322-0.189-0.653-0.363-0.995-0.516c-0.034-0.015-0.068-0.034-0.103-0.05
			c-0.385-0.167-0.784-0.307-1.192-0.426c-0.08-0.023-0.161-0.041-0.242-0.062c-0.327-0.087-0.66-0.157-1-0.211
			c-0.107-0.017-0.214-0.035-0.322-0.05c-0.417-0.053-0.838-0.088-1.27-0.088H86.324l120.718-85.834v59.195
			c0,5.591,4.533,10.124,10.124,10.124s10.124-4.532,10.124-10.124v-73.591l37.177-26.434V276.266z M367.172,286.545l-82.458,51.331
			V286.43L367.172,286.545z M425.291,139.481l-63.946,52.495v-52.457L425.291,139.481z M344.095,122.21
			c-0.004,0.004-0.008,0.01-0.012,0.014c-0.318,0.316-0.607,0.659-0.881,1.014c-0.064,0.082-0.126,0.164-0.185,0.248
			c-0.26,0.36-0.503,0.733-0.716,1.127c-0.007,0.013-0.012,0.027-0.019,0.041c-0.203,0.382-0.376,0.781-0.528,1.19
			c-0.031,0.083-0.063,0.165-0.091,0.249c-0.284,0.823-0.465,1.692-0.528,2.595c-0.008,0.118-0.013,0.236-0.018,0.354
			c-0.004,0.12-0.018,0.238-0.018,0.359v79.197l-56.384,46.287v-92.318c0-0.004,0-0.009,0-0.013v-0.094
			c0-0.044-0.006-0.086-0.006-0.13c-0.003-0.256-0.016-0.513-0.039-0.77c-0.009-0.106-0.021-0.212-0.034-0.317
			c-0.024-0.198-0.055-0.396-0.09-0.593c-0.021-0.121-0.042-0.243-0.069-0.362c-0.039-0.18-0.089-0.36-0.139-0.54
			c-0.033-0.12-0.062-0.241-0.099-0.358c-0.001-0.003-0.001-0.006-0.002-0.008c-0.086-0.27-0.184-0.536-0.292-0.795
			c-0.047-0.112-0.101-0.221-0.151-0.33c-0.077-0.169-0.155-0.336-0.241-0.499c-0.056-0.106-0.116-0.211-0.176-0.315
			c-0.094-0.165-0.192-0.328-0.296-0.487c-0.06-0.092-0.119-0.183-0.182-0.272c-0.122-0.176-0.251-0.346-0.384-0.512
			c-0.053-0.067-0.103-0.135-0.157-0.199c-0.385-0.461-0.809-0.884-1.265-1.266c-0.074-0.062-0.15-0.118-0.225-0.178
			c-0.158-0.125-0.317-0.245-0.482-0.36c-0.095-0.067-0.191-0.131-0.29-0.194c-0.155-0.1-0.313-0.195-0.473-0.288
			c-0.103-0.06-0.206-0.12-0.311-0.176c-0.188-0.099-0.381-0.189-0.575-0.276c-0.111-0.051-0.223-0.1-0.336-0.147
			c-0.231-0.094-0.466-0.18-0.705-0.258c-0.114-0.036-0.231-0.067-0.347-0.1c-0.18-0.051-0.36-0.099-0.545-0.14
			c-0.119-0.026-0.24-0.051-0.361-0.074c-0.198-0.036-0.399-0.067-0.601-0.092c-0.105-0.013-0.208-0.028-0.315-0.038
			c-0.31-0.028-0.623-0.048-0.938-0.048h-89.178L423.946,43.078L344.095,122.21z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M217.166,308.744c-13.028,0-13.049,20.247,0,20.247C230.194,328.991,230.215,308.744,217.166,308.744z" />
                            </g>
                        </g>
                    </svg>
                </SvgIcon>
            </IconButton>
            {isOpen && <IceBreakerModal open={isOpen} Username={Username} Profilid={Profilid} onClose={() => setisOpen(false)} onSend={onSend} />}
        </>
    );
};

export default IceBreakerIconButton;
